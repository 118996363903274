@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: 420px) { @content; }
  } @else if $media == bigger-handheld {
    @media only screen and (min-width: 420px) and (max-width: 600px) { @content; }
  } @else if $media == tablet {
    @media only screen and (min-width: 600px) and (max-width: 800px) { @content; }
  } @else if $media == laptop {
    @media only screen and (min-width: 800px) and (max-width: 1100px) { @content; }
  } @else if $media == wide {
    @media only screen and (min-width: 1100px) and (max-width: 1600px) { @content; }
  } @else if $media == extra-wide {
    @media only screen and (min-width: 1600px) and (max-width: 2000px) { @content; }
  } @else if $media == widest {
    @media only screen and (min-width: 2000px) { @content; }
  } @else if $media == iphone {
    @media only screen
      and (min-device-width: 375px)
      and (max-device-width: 667px)
      and (-webkit-min-device-pixel-ratio: 2)
      and (orientation: portrait) {
        @content;
    }
  }
}
