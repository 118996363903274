.main-container {
  flex-direction: column;

  .portrait {
    margin-top: $gutter;
    height: 48vh;
    width: auto;
    margin-bottom: $gutter * 2;
    aspect-ratio: attr(width) / attr(height);

    @include respond-to(tablet) {
      height: auto;
      width: 85%;
    }

    @include respond-to(iphone) {
      height: auto;
      width: 95%;
    }

    @include respond-to(bigger-handheld) {
      height: auto;
      width: 95%;
    }

    @include respond-to(handhelds) {
      height: auto;
      width: 95%;
    }
  }

  .my-name {
    margin-bottom: $gutter * 2;

    @include respond-to(iphone) {
      text-align: center;
    }

    @include respond-to(tablet) {
      text-align: center;
    }

    @include respond-to(bigger-handheld) {
      text-align: center;
      margin-bottom: 10vh;
    }

    @include respond-to(handhelds) {
      text-align: center;
      font-size: 170%;
      margin-bottom: 5vh;
    }
  }

  .story {
    @extend %flex-grid;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;

    section {
      @extend %flex-column;
      width: 27%;

      @include respond-to(extra-wide) {
        width: 37%;
      }

      @include respond-to(wide) {
        width: 40%;
      }

      @include respond-to(laptop) {
        width: 85%;
      }

      @include respond-to(tablet) {
        width: 85%;
      }

      @include respond-to(iphone) {
        width: 95%;
      }

      @include respond-to(bigger-handheld) {
        width: 95%;
      }

      @include respond-to(handhelds) {
        width: 99%;
      }
    }
  }
}
