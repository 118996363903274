//
// This is a manifest file that'll be compiled into application.css, which will include all the files
// listed below.
//
// Any CSS and SCSS file within this directory, lib/assets/stylesheets, vendor/assets/stylesheets,
// or any plugin's vendor/assets/stylesheets directory can be referenced here using a relative path.
//
// You're free to add application-wide styles to this file and they'll appear at the bottom of the
// compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
// files in this directory. Styles in this file should be added after the last require_* statement.
// It is generally better to create a new file per style scope.
//
// = require_self
//
@import 'reset';
@import 'typography-base';
@import 'helpers';
@import 'fonts';

//----------------------
// Colors
//----------------------

// Main
$default-font-color: #1D1D1D;
$lightest: #fff;
$light-gray: #ddd;
$dark-gray: #696969;
$background-color: #fff;

$overlay-background-color: #fefefe;
$shadow-border-color: #ededed;
$shadow-color: #e9e9e9;
$default-shadow: inset 0 0 1px $shadow-border-color, 0 2px 4px $shadow-color;

$link-color: #fb3584;

$success-color: #d7eecf;
$success-shade: darken($success-color, 30%);
$success-text-color: #2f642e;

$danger-color: #f00;
$danger-shade: darken($danger-color, 30%);
$danger-background-color: #eed5d5;

$info-color: #d1e8f4;
$info-shade: darken($info-color, 30%);
$info-text-color: darken($info-shade, 30%);

// Header
$header-background: transparent;

// Wishes
$wish-background: #008000;

// Actions
$primary-color: #2964AD; // Buttons, etc.
$primary-shade: darken($primary-color, 40%); // :hover, etc.
$secondary-color: #4E00E6; // Other buttons, etc.
$secondary-shade: #157334; // :hover, etc.
$basic-color: transparent;
$basic-shade: #ececec;

//======================

//----------------------
// Grid
//----------------------
$site-width: 98%;
$gutter: .75%;
$main-padding: 0 $gutter $gutter $gutter;
$default-margin-bottom: $gutter * 1.5;
//======================


//----------------------
// Fonts
//----------------------
$raleway: 'Raleway', sans-serif;
$fira_sans: FiraSans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$source_sans: 'SourceSans', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

$site-font-size: 72%;
$logo-font-family: 'Bree Serif', serif;
$default-font-family: $source_sans;
$default-font-size: 2.2rem;
$default-line-height: 1.2;
$header-font-family: $fira_sans;
$header-font-weight: bold;
$header-alternative-font-family: $source_sans;

* {
  margin: 0;
  padding: 0;
  font-family: $default-font-family;
  border: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $header-font-family;
  font-weight: $header-font-weight;
}

$h1-size: 4.5rem;
$h2-size: $h1-size / 1.62;
$h3-size: $h2-size / 1.62;
$h4-size: $h3-size / 1.62;
$h5-size: $h4-size / 1.62;
$h6-size: $h5-size / 1.62;

h1 { font-size: $h1-size; }
h2 { font-size: $h2-size; font-weight: 500; }
h3 { font-size: $h3-size; }
h4 { font-size: $h4-size; }
h5 { font-size: $h5-size; }
h6 { font-size: $h6-size; }

p {
  margin-bottom: $default-margin-bottom;
}

//======================

html {
  box-sizing: border-box;
  font-size: $site-font-size;

  @include respond-to(tablet) {
    font-size: 90%;
  }

  @include respond-to(iphone) {
    font-size: 120%;
  }

  @include respond-to(bigger-handheld) {
    font-size: 60%;
  }

  @include respond-to(handhelds) {
    font-size: 60%;
  }
}

html,
body {
  background-color: $background-color;
  color: $default-font-color;
  height: 100%;
}

body {
  align-items: flex-start;
  display: flex;
  font-family: $default-font-family;
  font-size: $default-font-size;
  line-height: $default-line-height;
  justify-content: center;
}

a {
  color: $link-color;
  font-weight: 900;
  font-family: $fira_sans;
  cursor: pointer;

  &:hover {
    color: #fbaf35;
  }
}

strong {
  font-weight: 600;
  font-family: $fira_sans;
  color: $secondary-color;
}

i {
  font-style: italic;
  font-size: 75%;
}

// Grid:

%flex-grid {
  align-content: flex-start;
  display: flex;
  justify-content: flex-start;
}

%flex-column {
  margin-bottom: $gutter;
  padding: $gutter;
}

.main-container {
  @extend %flex-grid;
  flex: 1;
  flex-direction: column;
  max-width: $site-width;
  min-height: 100%;
  padding: $main-padding;
  align-items: center;
}

.main-header {
  width: 100%;
  margin-top: $gutter;
  @extend %flex-grid;
  align-items: center;
  background-color: $header-background;
  border-bottom: 2px solid $secondary-color;
  padding-bottom: $gutter;

  .logo {
    flex: 1;
    text-decoration: none;

    h1 {
      font-family: $logo-font-family;
      font-size: $h2-size;
      color: $dark-gray; // $default-font-color;

      span {
        color: black;
      }
    }
  }
  .session-actions {
    flex: 4;
    text-align: right;
  }
}

.main-flashes {
  margin-top: $gutter;
}

.main-content {
  flex: 2 0;
  @extend %flex-grid;
  flex-direction: column;
  width: 100%;
}

.main-footer {
  @extend %flex-grid;
  align-self: flex-end;
  flex-direction: column;
  width: 100%;

  section {
    @extend %flex-column;

    header {
      font-weight: bold;
    }

    ul {
      list-style-type: none;

      li {
        font-size: $default-font-size - .2rem;
      }
    }
  }
}

// Components
// @import "components/alerts";
// @import "components/buttons";
// @import "components/breadcrumbs";
// @import "components/datepicker";
// @import "components/forms";
// @import "components/index-container";
// @import "components/labels";
// @import "components/tables";
// @import "components/tooltips";
// @import "components/user-badge";

// Sections
@import "sections/main";

