@font-face { font-family: 'FiraSans';
  src: url('./fonts/FiraSans-Bold.eot');
  src: url('./fonts/FiraSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/FiraSans-Bold.woff2') format('woff2'),
  url('./fonts/FiraSans-Bold.woff') format('woff'),
  url('./fonts/FiraSans-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face{
  font-family: 'SourceSans';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  src: url('./fonts/SourceSansPro-Light.ttf.woff2') format('woff2'),
       url('./fonts/SourceSansPro-Light.otf.woff') format('woff');
  font-display: swap;
}
